import Behaviour from './Behaviour'

import Highcharts from 'highcharts'
import Exporting from 'highcharts/modules/exporting'

Exporting(Highcharts)

const formatTooltip = function(x, points){
  let s = [];

  s.push(x);
  points.forEach(function(point){
    s.push(tooltipTextLabel(point.series.name, point.y));
    return
  });
  return s;
}

const tooltipTextLabel = function(name, point){
  if (point != 0) {
    return `<b>${name}</b>: ${toHour(point)} h ${toMinutes(point)} m`;
  } else {
    return `<b>${name}</b>: N\D`;
  }
}

const toHour = hours => Math.floor(hours);

const toMinutes = hours => Math.floor((hours % 1) * 60);

export default class BudgetConsumption extends Behaviour {
  init() {
    console.log("Init budget consumption charts");

    const data = this.data("values");
    this.drawChart(data);
  }

  drawChart(data) {
    const categories = data.categories;
    const series = data.series;

    Highcharts.chart("budget_consumption", {
      xAxis: {
        categories: categories,
        tickmarkPlacement: "on",
        title: {
          enabled: true
        }
      },
      yAxis: {
        title: {
          text: ""
        }
      },
      title:{
        text: "Storico"
      },
      tooltip: {
        formatter() { return formatTooltip(this.x, this.points) },
        split: true
      },
      series: series
    });
  }
}
