import Behaviour from './Behaviour'

export default class IterationHoursInput extends Behaviour {
  init() {
    console.log('Init IterationHoursInput behaviour')
    this.$control = this.$dom.find('.js-iteration-hours-control')
    this.$iterationInputs = this.$dom.find('.js-iteration-input')
    this.$hourInputs = this.$dom.find('.js-iteration-hour-input')
    this.$hourlyCost = this.$dom.find('.js-budget-hourly-cost')

    this.$control.click(_e => { this.handleControlChange() })
    this.$control.ready(_e => { this.handleSetInitialInputValues() })
    this.handleControlChange()
  }

  isControlChecked() {
    return this.$control.is(':checked')
  }

  handleControlChange() {
    if (this.isControlChecked()) {
      this.showHourInputs()
      this.hideIterationInputs()
    } else {
      this.showIterationInputs()
      this.hideHourInputs()
    }

    this.handleSetInitialInputValues()
    this.handleSetInputValues()
  }

  showHourInputs() {
    this.$hourInputs.show()
  }

  hideHourInputs() {
    this.$hourInputs.hide()
  }

  showIterationInputs() {
    this.$iterationInputs.show()
  }

  hideIterationInputs() {
    this.$iterationInputs.hide()
  }

  handleSetInputValues() {
    const iterationMin = this.$dom.find('.js-iteration-min')
    const iterationMax = this.$dom.find('.js-iteration-max')
    const hourMin = this.$dom.find('.js-iteration-hour-min')
    const hourMax = this.$dom.find('.js-iteration-hour-max')
    const hourlyCost = this.$dom.find('.js-budget-hourly-cost')
    const hourlyCostVal = parseFloat(
      hourlyCost.val() || this.$dom.data('hourlyCost')
    )

    hourlyCost.keyup(e => {
      if (e.target.value !== '' && !e.target.isNaN && this.isControlChecked()) {
        iterationMin.val(hourMin.val() * hourlyCostVal)
        iterationMax.val(hourMax.val() * hourlyCostVal)
        iterationMin.trigger('change')
        iterationMax.trigger('change')
      }
    })

    hourMin.keyup(_e => {
      if (hourlyCost.val() !== '' && this.isControlChecked()) {
        iterationMin.val(hourMin.val() * hourlyCostVal)
        iterationMin.trigger('change')
      }
    })

    hourMax.change(_e => {
      if (hourlyCost.val() !== '' && this.isControlChecked()) {
        iterationMax.val(hourMax.val() * hourlyCostVal)
        iterationMax.trigger('change')
      }
    })
  }

  handleSetInitialInputValues() {
    const iterationMin = this.$dom.find('.js-iteration-min')
    const iterationMax = this.$dom.find('.js-iteration-max')
    const hourMin = this.$dom.find('.js-iteration-hour-min')
    const hourMax = this.$dom.find('.js-iteration-hour-max')
    const hourlyCost = parseFloat(
      this.$dom.find('.js-budget-hourly-cost').val()
      || this.$dom.data('hourlyCost')
    )

    if (iterationMin.val() !== '') {
      hourMin.val(Math.round(iterationMin.val() / hourlyCost))
    }

    if (iterationMax.val() !== '') {
      hourMax.val(Math.round(iterationMax.val() / hourlyCost))
    }
  }
}
