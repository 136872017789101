/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import "trix/dist/trix.css";
import "../src/application.sass";

import {
  BudgetConsumption,
  BudgetHoursInput,
  BudgetManyIterationsInput,
  IterationHoursInput,
  IterationAreaCalculator,
  IterationGauge,
  IterationInfoToggle,
  DescriptionToggle,
  DatePicker,
  ModalToggle,
  OpenContentTab,
  OpenMenu,
} from 'behaviours'

import 'cocoon-js'
import { } from "trix"
import Chartkick from "chartkick"
import Chart from "chart.js"

require('@rails/ujs').start()
require("@rails/activestorage").start()

import $ from 'jquery'

const init = () => {
  BudgetConsumption.mount('.js-budget-consumption')
  BudgetHoursInput.mount('.js-hours-input')
  BudgetManyIterationsInput.mount('.js-budget-many-iterations-input')
  IterationHoursInput.mount('.js-iteration-hours-input')
  IterationAreaCalculator.mount('.js-iteration-area-calculator')
  IterationGauge.mount('.js-iteration-gauge')
  IterationInfoToggle.mount('.js-iteration-info-toggle')
  DescriptionToggle.mount('.js-description-toggle')
  DatePicker.mount('.form__input.date')
  ModalToggle.mount('.js-modal-toggle')
  OpenContentTab.mount('.js-show-tab')
  OpenMenu.mount('.js-open-menu')
}

document.addEventListener("DOMContentLoaded", () => {
  init()
})

$(document).on("cocoon:after-insert", function () {
  console.log("cocoon:after-insert event triggered");

  return init()
});
