import Behaviour from './Behaviour'

export default class OpenContentTab extends Behaviour {
  init () {
    this.$dom.click(e => {
      e.preventDefault()

      const $currentTab = this.$dom
      const $currentContentTab = $($currentTab.attr('href'))

      $('.tab__item__link, .tab__content__item').removeClass('is-active')

      $currentTab.addClass('is-active')
      $currentContentTab.addClass('is-active')
    })
  }
}
