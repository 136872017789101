import Behaviour from './Behaviour'
import flatpickr from "flatpickr";
import { Italian } from "flatpickr/dist/l10n/it.js"
import { english } from "flatpickr/dist/l10n/default.js"
require("flatpickr/dist/themes/airbnb.css");

export default class DatePicker extends Behaviour {
  init() {
    const inputDate = document.querySelector("input[type=date]")
    const minDate = inputDate.getAttribute("min");
    const maxDate = inputDate.getAttribute("max");
    const language = window.navigator.userLanguage || window.navigator.language;
    const placeholder = language.includes('en')? "Select Date" : "Seleziona data"
    inputDate.setAttribute("placeholder", placeholder)
    inputDate.flatpickr({
      "locale": language.includes('en')? english : Italian,
      altInput: true,
      altFormat: "j F Y",
      dateFormat: "Y-m-d",
      defaultDate: inputDate.value,
      minDate: minDate,
      maxDate: maxDate
    });

  }
}
