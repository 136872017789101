export default class Behaviour {
  static mount(selector) {
    $(document).ready(() => {
      const start = () => {
        $(selector).each((i, elem) =>  {
          const $elem = $(elem)
          const builtName = `build-${selector}`
          if ($elem.data(builtName)) { return }
          $elem.data(builtName, true)

          new this(elem).init()
        })
      }

      start()
    })
  }

  constructor(dom) {
    this.dom = dom
    this.$dom = $(this.dom)
  }

  data(name) {
    return this.$dom.data(name)
  }

  domData(name) {
    return $(this.data(name))
  }
}
