import Behaviour from './Behaviour'

export default class ModalToggle extends Behaviour {
  init() {
    return this.$dom.click(e => {
      e.preventDefault()

      const $element = this.domData("element")

      $element.toggleClass('is-active')
    })
  }
}
