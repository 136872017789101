import Behaviour from './Behaviour'

export default class DescriptionToggle extends Behaviour {
  init() {

    const $card = this.$dom.closest(".card")
    const $descContaier = $card.find(".js-description-dropdown");
    const $descContent = $card.find(".js-description-dropdown > *");
    const $descContainerHeight = $descContaier.innerHeight();
    const $descContentHeight = $descContent.innerHeight();
    const $descContaierToggle = this.$dom;

    if ($descContentHeight > $descContainerHeight) {
      $descContaier.addClass("is-long")
      $descContaierToggle.parent().show()
    }

    return $descContaierToggle.click(e => {
      e.preventDefault();
      if ($descContaier.innerHeight() < $descContentHeight) {
        $descContaier.css("max-height", ($descContentHeight));
        $descContaier.toggleClass("is-long")
      } else {
        $descContaier.css("max-height", ($descContainerHeight));
        $descContaier.toggleClass("is-long")
      }
    });
  }
}
