import Behaviour from './Behaviour'

export default class BudgetHoursInput extends Behaviour {
  init() {
    console.log('Init BudgetHoursInput behaviour')
    this.$control = this.$dom.find('.js-hours-control')
    this.$budgetInputs = this.$dom.find('.js-budget-input')
    this.$hourInputs = this.$dom.find('.js-hour-input')
    this.$hourlyCost = this.$dom.find('.js-budget-hourly-cost')

    this.$control.click(_e => { this.handleControlChange() })
    this.$control.ready(_e => { this.handleSetInitialInputValues() })
    // this.$hourlyCost.change(e => { this.handleSetInputValues() })
    this.handleControlChange()
  }

  isControlChecked() {
    return this.$control.is(':checked')
  }

  handleControlChange() {
    if (this.isControlChecked()) {
      this.showHourInputs()
      this.hideBudgetInputs()
    } else {
      this.showBudgetInputs()
      this.hideHourInputs()
    }

    this.handleSetInitialInputValues()
    this.handleSetInputValues()
  }

  hideBudgetInputs() {
    this.$budgetInputs.hide()
  }

  showBudgetInputs() {
    this.$budgetInputs.show()
  }

  hideHourInputs() {
    this.$hourInputs.hide()
  }

  showHourInputs() {
    this.$hourInputs.show()
  }

  handleSetInputValues() {
    const budgetMin = this.$dom.find('.js-budget-min')
    const budgetMax = this.$dom.find('.js-budget-max')
    const hourMin = this.$dom.find('.js-hour-min')
    const hourMax = this.$dom.find('.js-hour-max')
    const hourlyCost = this.$dom.find('.js-budget-hourly-cost')

    hourlyCost.keyup(e => {
      if (e.target.value !== '' && !e.target.isNaN && this.isControlChecked()) {
        budgetMin.val(hourMin.val() * hourlyCost.val())
        budgetMax.val(hourMax.val() * hourlyCost.val())
        budgetMin.trigger('change')
        budgetMax.trigger('change')
      }
    })

    hourMin.keyup(_e => {
      if (hourlyCost.val() !== '' && this.isControlChecked()) {
        budgetMin.val(hourMin.val() * hourlyCost.val())
        budgetMin.trigger('change')
      }
    })

    hourMax.change(_e => {
      if (hourlyCost.val() !== '' && this.isControlChecked()) {
        budgetMax.val(hourMax.val() * hourlyCost.val())
        budgetMax.trigger('change')
      }
    })
  }

  handleSetInitialInputValues() {
    const budgetMin = this.$dom.find('.js-budget-min')
    const budgetMax = this.$dom.find('.js-budget-max')
    const hourMin = this.$dom.find('.js-hour-min')
    const hourMax = this.$dom.find('.js-hour-max')
    const hourlyCost = this.$dom.find('.js-budget-hourly-cost')

    if (budgetMin.val() !== '') {
      hourMin.val(Math.round(budgetMin.val() / hourlyCost.val()))
    }

    if (budgetMax.val() !== '') {
      hourMax.val(Math.round(budgetMax.val() / hourlyCost.val()))
    }
  }
}
