import Behaviour from './Behaviour'

export default class  IterationInfoToggle  extends Behaviour {
  init() {
    console.log("Init IterationInfoToggle behaviour");

    return this.$dom.click(e => {
      e.preventDefault();

      const selectorToToggle = this.data("element");
      const elementToToggle = $(selectorToToggle);

      elementToToggle.toggleClass("is-sr-only");
    });
  }
}
