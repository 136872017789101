import Behaviour from './Behaviour'

export default class BudgetManyIterationsInput extends Behaviour {
  init() {
    console.log("Init BudgetManyIterationsInput behaviour")
    this.$control = this.$dom.find(".js-control")

    this.$budgetTitle = this.$dom.find(".js-budget-title")
    this.$budgetMin = this.$dom.find(".js-budget-min")
    this.$budgetMax = this.$dom.find(".js-budget-max")
    this.$budgetDescription = this.$dom.find(".js-budget-description")
    this.$budgetDeliveryScheduledOn = this.$dom.find(".js-budget-delivery-scheduled-on")

    this.$iterationTitle = this.$dom.find(".js-iteration-title")
    this.$iterationMin = this.$dom.find(".js-iteration-min")
    this.$iterationMax = this.$dom.find(".js-iteration-max")
    this.$iterationDescription = this.$dom.find(".js-iteration-description")
    this.$iterationDeliveryScheduledOn = this.$dom.find(".js-iteration-delivery-scheduled-on")

    this.$iterationFields = this.$dom.find(".js-iteration-fields")

    this.$control.change(e => this.handleControlChange())

    this.$budgetTitle.change(e => this.copyBudgetAttributesIntoIteration())
    this.$budgetMin.change(e => this.copyBudgetAttributesIntoIteration())
    this.$budgetMax.change(e => this.copyBudgetAttributesIntoIteration())
    this.$budgetDescription.change(e => this.copyBudgetAttributesIntoIteration())
    this.$budgetDeliveryScheduledOn.change(e => this.copyBudgetAttributesIntoIteration())

    this.iterationOriginalTitle = this.$iterationTitle.val()
    this.iterationOriginalMin = this.$iterationMin.val()
    this.iterationOriginalMax = this.$iterationMax.val()
    this.iterationOriginalDeliveryScheduledOn = this.$iterationDeliveryScheduledOn.val()
    this.iterationOriginalDescription = this.$iterationDescription.val()

    if (this.isControlChecked()) {
      this.$iterationFields.show()
    } else {
      this.$iterationFields.hide()
    }
  }

  handleControlChange() {
    if (this.isControlChecked()) {
      this.cleanIterationInput()
      this.$iterationFields.show()
    } else {
      this.$iterationFields.hide()
      this.copyBudgetAttributesIntoIteration()
    }
  }

  isControlChecked() {
    return this.$control.is(":checked")
  }

  copyBudgetAttributesIntoIteration() {
    if ( this.isControlChecked() ) {
      return
    }
    this.$iterationTitle.val( this.$budgetTitle.val() ).trigger("change")
    this.$iterationMin.val( this.$budgetMin.val() ).trigger("change")
    this.$iterationMax.val( this.$budgetMax.val() ).trigger("change")
    this.$iterationDescription.val( this.$budgetDescription.val() ).trigger("change")
    this.$iterationDeliveryScheduledOn.val( this.$budgetDeliveryScheduledOn.val() ).trigger("change")
  }

  cleanIterationInput() {
    if ( !this.isControlChecked() ) {
      return
    }

    this.$iterationTitle.val(this.iterationOriginalTitle).trigger("change")
    this.$iterationMin.val(this.iterationOriginalMin).trigger("change")
    this.$iterationMax.val(this.iterationOriginalMax).trigger("change")
    this.$iterationDescription.val(this.iterationOriginalDescription).trigger("change")
    this.$iterationDeliveryScheduledOn.val(this.iterationOriginalDeliveryScheduledOn).trigger("change")
   }
}
