import Behaviour from './Behaviour'

export default class IterationAreaCalculator extends Behaviour {
  init() {
    this.$iterationMinInput = this.$dom.find("input.js-iteration-min")
    this.$inputs = this.$dom.find("input.js-iteration-area-value")
    this.$allocatedHours = this.$dom.find("#allocated")
    this.$totalHours = this.$dom.find("#total")
    this.$availableHours = this.$dom.find("#available")
    this.$hourlyCost = this.$dom.find("input.js-budget-hourly-cost")

    this.$inputs.change(e => this.calculateInputValues())
    this.$iterationMinInput.change(e => this.calculateInputValues())
    this.$hourlyCost.change(e => this.calculateInputValues())

    this.calculateInputValues()
  }

  calculateInputValues() {
    const hourlyCost = parseFloat(
      this.$dom.data("hourlyCost") ||
      this.$dom.find(".js-budget-hourly-cost").val()
    )

    const minValue = parseFloat(this.$iterationMinInput.val())

    const allocatedlHours = $.map(
      this.$inputs,
      ((input, i) => parseFloat($(input).val()))
    ).reduce(((total, value) => total + value), 0).toFixed(1)

    const totalHours = (minValue / hourlyCost).toFixed(1)
    const availableHours = (totalHours - allocatedlHours).toFixed(1)

    this.$totalHours.val(`Totali: ${totalHours}`)
    this.$allocatedHours.val(`Allocate: ${allocatedlHours}`)
    this.$availableHours.val(`Residue: ${availableHours}`)

    if (availableHours < 0) {
      this.$availableHours.addClass("is-danger")
    } else {
      this.$availableHours.removeClass("is-danger")
    }
  }
}
