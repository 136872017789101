import Behaviour from './Behaviour'

export default class OpenMenu extends Behaviour {
  init() {
    this.$dom.click(e => {
      e.preventDefault()

      const $target = this.domData("target")

      $target.toggleClass('is-active')
      this.$dom.toggleClass('is-active')
    })
  }
}
