import Behaviour from './Behaviour'

import Highcharts from 'highcharts'
import HighchartsMore from 'highcharts/highcharts-more'
import Exporting from 'highcharts/modules/exporting'
import SolidGauge from 'highcharts/modules/solid-gauge'

Exporting(Highcharts)
HighchartsMore(Highcharts)
SolidGauge(Highcharts)

export default class IterationGauge extends Behaviour {
  init() {
    const data = this.data("values")

    this.drawChart(data)
  }

  drawChart(
    data
  ) {
    const series = data.series
    const pane = data.pane

    let chart = Highcharts.chart("iteration_gauge", {
      chart: {
        type: 'solidgauge',
        height: '60%'
      },
      title: {
        text: ""
      },
      tooltip: {
        hideDelay: 100000,
        borderWidth: "0px",
        backgroundColor: 'none',
        shadow: false,
        style: {
          fontSize: '12px'
        },
        pointFormat: '{series.name}<br><span style="font-size:2em; color: {point.color}; font-weight: bold">{point.y}%</span>',
        positioner: function (labelWidth) {
          return {
            x: (this.chart.chartWidth - labelWidth) / 2,
            y: (this.chart.plotHeight / 2) - 20
          };
        }
      },
      pane: pane,
      yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: []
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: false
          },
          linecap: 'round',
          stickyTracking: true,
          rounded: true
        }
      },
      series: series
    });

    chart.tooltip.refresh(chart.series[0].points[0])
  }
}
